exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leistungen-buero-organisation-js": () => import("./../../../src/pages/leistungen/buero-organisation.js" /* webpackChunkName: "component---src-pages-leistungen-buero-organisation-js" */),
  "component---src-pages-leistungen-consulting-js": () => import("./../../../src/pages/leistungen/consulting.js" /* webpackChunkName: "component---src-pages-leistungen-consulting-js" */),
  "component---src-pages-leistungen-controlling-js": () => import("./../../../src/pages/leistungen/controlling.js" /* webpackChunkName: "component---src-pages-leistungen-controlling-js" */),
  "component---src-pages-leistungen-existenzgruendung-js": () => import("./../../../src/pages/leistungen/existenzgruendung.js" /* webpackChunkName: "component---src-pages-leistungen-existenzgruendung-js" */),
  "component---src-pages-leistungen-lohn-und-gehalt-js": () => import("./../../../src/pages/leistungen/lohn-und-gehalt.js" /* webpackChunkName: "component---src-pages-leistungen-lohn-und-gehalt-js" */),
  "component---src-pages-leistungen-rechnungswesen-js": () => import("./../../../src/pages/leistungen/rechnungswesen.js" /* webpackChunkName: "component---src-pages-leistungen-rechnungswesen-js" */),
  "component---src-pages-meta-datenschutz-js": () => import("./../../../src/pages/meta/datenschutz.js" /* webpackChunkName: "component---src-pages-meta-datenschutz-js" */),
  "component---src-pages-meta-impressum-js": () => import("./../../../src/pages/meta/impressum.js" /* webpackChunkName: "component---src-pages-meta-impressum-js" */),
  "component---src-pages-meta-kontakt-js": () => import("./../../../src/pages/meta/kontakt.js" /* webpackChunkName: "component---src-pages-meta-kontakt-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

